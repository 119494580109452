<template>
    <div class="key__filter__tag__inner" :id="bar+'-rule-'+rule.id" @click="ruleClicked">
        <span class="key__filter__tag__inner_word">
            {{operatorAlias}}

            {{ rule.valueAlias }}
        </span>

        <span class="key__filter__tag__keywords" :class="{'no-group-keywords': !checkedWords.length}" @click.stop>
            <b-dropdown id="dropdown-keyword" class="dropdown-keyword" text="">
                <template #button-content>
                    <span>
                        <template v-if="checkedWords.length">
                            {{checkedWords.length}}
                        </template>
                    </span>
                </template>

                <div>
                    <template v-if="groupWords.length || stringHelper.hasMultipleWords(rule.value)">
                        <header class="dropdown-keyword__header">
                            <template v-if="bar === 'main' && !rule.negative">
                                <div class="keyword-switcher">
                                        Narrower
                                        <input type="checkbox" v-model="localRule.broadMode" :id="`${bar}-${rule.id}-keyword-switcher`">
                                        <label :for="`${bar}-${rule.id}-keyword-switcher`"></label>
                                        Broader
                                </div>
                            </template>

                            <template v-else>
                                Select keywords to include
                            </template>
                            <!-- <a v-if="bar === 'prompt'" href="#" class="dropdown-keyword__header__settings" @click="openSettingsModal"></a> -->
                        </header>

                        <ul class="dropdown-keyword__list">
                            <li v-for="(word, index) in groupWords" class="input-checkbox" :key="index">
                                <input type="checkbox" v-model="groupWords[index].checked" :id="`${bar}-${rule.id}-word-${index}`"/>
                                <label :for="`${bar}-${rule.id}-word-${index}`">{{word.word}}</label>
                            </li>
                        </ul>
                    </template>

                    <header v-else-if="!$store.getters.settings.disable_ai" class="dropdown-keyword__header">
                        <p>Get AI suggestions for related keywords or add your own</p>
                    </header>

                    <div v-if="addKeywordMode" class="input-checkbox">
                        <label for="enter-ch">
                            <input
                                ref="newKeywordInput"
                                v-model="newKeyword"
                                type="text"
                                placeholder="Enter a keyword"
                                class="input-checkbox__input"
                                @keyup.enter="addKeyword"
                                @blur="addKeyword"
                            />
                        </label>
                    </div>

                    <div v-else class="dropdown-keyword__list__controls">
                        <a v-if="groupWords.length" href="#" @click="addKeywordMode = true">+ Add Keyword</a>

                        <button
                            v-if="!$store.getters.settings.disable_ai"
                            class="button button--reload"
                            @click="reloadGroupWords"
                        >
                            <template v-if="loadingSuggestions">
                                <div class="button--reload__inner button--reload__active">
                                    Loading...
                                </div>
                            </template>

                            <template v-else>
                                <template v-if="this.groupWords.length">
                                    Reload Suggestions
                                </template>
                                <template v-else>
                                    <span class="button--reload__suggest">
                                        Load Suggested Keywords
                                    </span>
                                </template>
                            </template>
                        </button>

                        <a v-if="!groupWords.length" href="#" @click="addKeywordMode = true">
                            + Add Keyword
                            <template v-if="!$store.getters.settings.disable_ai"> Manually</template>
                        </a>
                    </div>
                </div>
            </b-dropdown>
        </span>

        <span class="key__filter__close" @click.stop="deleteRule"></span>
    </div>
</template>

<script>
import equationRule from "./equationRule";
import searchService from "../../services/search.service";

export default {
    props: {
        rule: Object,
        bar: String,
        aiText: String,

        isNested: {
            type: Boolean,
            default: false,
        }
    },

    mixins: [equationRule],

    data() {
        return {
            groupWords: [],
            loadingSuggestions: false,
            addKeywordMode: false,
            newKeyword: '',
            watchKeywords: false,
            localRule: this.rule,
        }
    },

    mounted() {
        if ('suggestedGroupWords' in this.rule) {
            this.groupWords = this.rule.suggestedGroupWords.map(word => ({
                word: word,
                checked: this.rule.groupWords.includes(word),
            }));
        }

        this.$nextTick(() => {
            this.watchKeywords = true;
        });
    },

    watch: {
        checkedWords() {
            if (this.watchKeywords) {
                this.localRule.groupWords = this.checkedWords.map(word => word.word);
            }
        },

        addKeywordMode() {
            if (this.addKeywordMode) {
                this.$nextTick(() => {
                    this.$refs.newKeywordInput.focus();
                });
            }
        },
    },

    computed: {
        checkedWords() {
            return this.groupWords.filter(word => word.checked);
        },
    },

    methods: {
        selectAllWords() {
            this.groupWords.forEach(word => word.checked = true);
        },

        deselectAllWords() {
            this.groupWords.forEach(word => word.checked = false);
        },

        reloadGroupWords() {
            this.loadingSuggestions = true;

            searchService.getRelatedKeywords(this.rule.value, this.aiText)
                .then(response => {
                    if (response.data.length) {
                        this.groupWords = response.data.map(word => ({
                            word: word,
                            checked: true,
                        }));

                        this.localRule.suggestedGroupWords = response.data;
                    }
                })
                .finally(() => {
                    this.loadingSuggestions = false;
                });
        },

        addKeyword() {
            if (this.newKeyword) {
                this.groupWords.push({
                    word: this.newKeyword,
                    checked: true,
                });

                if (!('suggestedGroupWords' in this.rule)) {
                    this.localRule.suggestedGroupWords = [];
                }

                this.localRule.suggestedGroupWords.push(this.newKeyword);
            }

            this.newKeyword = '';
            this.addKeywordMode = false;
        },

        ruleClicked() {
            this.invertRule();
        },

        openSettingsModal() {
            this.$emit('open-settings-modal');
        }
    },
}
</script>

<style scoped>
</style>
