<template>
    <b-button variant="primary" size="lg" @click="() => {$emit('click')}" :disabled="disabled || loading">
        <template v-if="loading">
            {{loadingCaption}}
        </template>
        <template v-else>
            {{caption}}
        </template>
    </b-button>
</template>

<script>
    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            caption: {
                type: String,
                default: 'Save',
            },
            loadingCaption: {
                type: String,
                default: 'Saving...',
            },
            loading: {
                type: Boolean,
                default: false,
            }
        }
    }
</script>
