<template>
    <b-modal v-model="showModal" modal-class="modal-transparent" :hide-header="true" :hide-footer="true" size="md">
        <div class="embed-responsive embed-responsive-16by9">
            <iframe width="420" height="315" :src="src"></iframe>
        </div>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            src: String,
        }
    },

    methods: {
        show(src) {
            this.src = src;
            this.showModal = true;
        }
    }
}
</script>

<style scoped>

</style>
