import axios from '@/axios';

const RESOURCE_NAME = '/admin/helper-items';

export default {
    getList(data) {
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-item?id=${id}`)
    },

    getVideoInfo(videoId = '') {
        return axios.get(`${RESOURCE_NAME}/get-video-info?video_id=${videoId}`)
    },

    getEnterprisesList(search) {
        return axios.get(`${RESOURCE_NAME}/get-enterprises-list?search=${search}`);
    },

    uploadImage(data) {
        return axios.post(`${RESOURCE_NAME}/upload-file`, data);
    },

    getCommonItems() {
        return axios.get(`${RESOURCE_NAME}/get-common-items`);
    }
}
