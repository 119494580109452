export default {
    ENTERPRISE: 'enterprise',
    ECOSYSTEM: 'ecosystem',
    STARTUP: 'startup',
}

export const licenseTypes = {
    'free': 'Free',
    'paid': 'Paid',
    'test': 'Test',
    'pilot': 'Pilot',
    'trial': 'Trial',
    'event_limited': 'Event Limited',
}

export const ACCOUNT_TYPE_ECOSYSTEM = 'ecosystem';
export const ACCOUNT_TYPE_PREMIUM = 'premium';
export const ACCOUNT_TYPE_PREMIUM_TEASER = 'premiumTeaser';
export const ACCOUNT_TYPE_EVENT_LIMITED = 'event_limited';
export const ACCOUNT_TYPE_FREE = 'free';
export const ACCOUNT_TYPE_INVITED = 'invited';

export const ecosystemTypes = {
    'incubator': 'Incubator',
    'accelerator': 'Accelerator',
    'university': 'University',
    'event': 'Event',
    'venture-capitalist': 'Venture Capitalist',
    'association': 'Association',
}
