<template>
    <div class="main-inner">
        <div class="cols">
            <div class="column column2">
                <h3 class="heading3">Startup Type</h3>
                <div class="card">
                    <StartupsAccessLevelsChart
                        ref="startupsByAccessLevelsChart"
                        :loading="loadingTypesChart"
                        @openStartupsModal="(sets) => showChartStartups('accessLevels', sets)"
                    />
                </div>
            </div>

            <div class="column column2">
                <h3 class="heading3">Startup Source</h3>
                <div class="card">
                    <StartupsBySource
                        ref="startupsBySourceChart"
                        :loading="loadingSourceChart"
                        @openStartupsModal="(sets) => showChartStartups('sources', sets)"
                    />
                </div>
            </div>
        </div>

        <b-dropdown text="Filter" class="dropdown--filter">
            <b-dropdown-form>
                <div class="d-sm-flex align-items-center mb-3">
                    <div class="dropdown-hover mb-2 mb-sm-0">
                        <span class="btn btn-primary">{{selectedPeriodLabel}}</span>
                        <div class="dropdown-hover__content">
                            <a v-for="period in periods"
                            :key="period.value"
                            :active="selectedPeriod === period.value"
                            @click="() => {selectedPeriod = period.value}">{{ period.label }}</a>
                        </div>
                    </div>

                    <div class="input-checkbox ml-sm-2">
                        <input id="a1" type="checkbox" v-model="filterTracked">
                        <label for="a1">Tracked</label>
                    </div>
                </div>

                <multiselect
                    v-if="filterTracked"
                    v-model="filterTrackedType"
                    :options="filterTrackedTypeOptions"
                    label="name"
                    track-by="name"
                    class="multiselect-mid mb-2"
                />

                Startup Visibility Type
                <multiselect
                    v-model="startupVisibilityType"
                    :options="startupsVisibilityTypes"
                    class="multiselect-mid mb-2"
                />

                Ecosystem Status
                <multiselect
                    v-model="ecosystemStatus"
                    :options="ecosystemStatuses"
                    class="multiselect-mid"
                />
            </b-dropdown-form>
        </b-dropdown>

        <div class="cols">
            <div class="column column1">
                <div class="card">
                    <div class="progress-primary">
                        <h5 class="progress-heading">Startups synced with Crunchbase</h5>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <template v-else>
                            <b-progress :value="counts.startups_cb_synced.goal - counts.startups_cb_synced.count" :max="counts.startups_cb_synced.goal"></b-progress>

                            <div class="progress-counter"><a @click="() => {if (counts.startups_cb_synced.count) showStartups('startups_cb_synced')}">{{stringHelper.formatNumber(counts.startups_cb_synced.count)}} left</a> of {{stringHelper.formatNumber(counts.startups_cb_synced.goal)}} goal</div>
                        </template>
                    </div>

                    <div class="progress-primary">
                        <h5 class="progress-heading">Tracked Startups synced with Crunchbase</h5>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <template v-else>
                            <b-progress :value="counts.tracked_startups_cb_synced.goal - counts.tracked_startups_cb_synced.count" :max="counts.tracked_startups_cb_synced.goal"></b-progress>

                            <div class="progress-counter"><a @click="() => {if (counts.tracked_startups_cb_synced.count) showStartups('tracked_startups_cb_synced')}">{{stringHelper.formatNumber(counts.tracked_startups_cb_synced.count)}} left</a> of {{stringHelper.formatNumber(counts.tracked_startups_cb_synced.goal)}} goal</div>
                        </template>
                    </div>
                </div>

                <div class="card">
                    <div class="progress-primary">
                        <h5 class="progress-heading">Ecosystems synced with Crunchbase</h5>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <template v-else>
                            <b-progress :value="counts.ecosystems_cb_synced.goal - counts.ecosystems_cb_synced.count" :max="counts.ecosystems_cb_synced.goal"></b-progress>

                            <div class="progress-counter"><a @click="() => {if (counts.ecosystems_cb_synced.count) showStartups('ecosystems_cb_synced')}">{{stringHelper.formatNumber(counts.ecosystems_cb_synced.count)}} left</a> of {{stringHelper.formatNumber(counts.ecosystems_cb_synced.goal)}} goal</div>
                        </template>
                    </div>
                    
                    <div class="progress-primary">
                        <h5 class="progress-heading">Ecosystems relations synced with Crunchbase</h5>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <template v-else>
                            <b-progress :value="counts.ecosystems_relations_cb_synced.goal - counts.ecosystems_relations_cb_synced.count" :max="counts.ecosystems_relations_cb_synced.goal"></b-progress>

                            <div class="progress-counter"><a @click="() => {if (counts.ecosystems_relations_cb_synced.count) showStartups('ecosystems_relations_cb_synced')}">{{stringHelper.formatNumber(counts.ecosystems_relations_cb_synced.count)}} left</a> of {{stringHelper.formatNumber(counts.ecosystems_relations_cb_synced.goal)}} goal</div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="column column2-3">
                <div class="card card--block">
                    <div class="card__content">
                        <h4 class="heading4 heading4--secondary">Populated Clients via PPX</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.ppx_clients) showStartups('ppx_clients')}">{{stringHelper.formatNumber(counts.ppx_clients)}}</a></big>
                    </div>

                    <footer class="card__footer">
                        <h4 class="heading4 heading4--secondary">Populated Features via PPX</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.ppx_features) showStartups('ppx_features')}">{{stringHelper.formatNumber(counts.ppx_features)}}</a></big>
                    </footer>
                </div>

                <div class="card card--block">
                    <div class="card__content">
                        <h4 class="heading4 heading4--secondary">Websites crawled via Faiz</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.faiz_crawled) showStartups('faiz_crawled')}">{{stringHelper.formatNumber(counts.faiz_crawled)}}</a></big>
                    </div>

                    <footer class="card__footer">
                        <h4 class="heading4 heading4--secondary">Websites crawled via Diffbot</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.diffbot_crawled) showStartups('diffbot_crawled')}">{{stringHelper.formatNumber(counts.diffbot_crawled)}}</a></big>
                    </footer>
                </div>

                <div class="card card--block">
                    <div class="card__content">
                        <h4 class="heading4 heading4--secondary">Diffbot Keywords populated</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.diffbot_keywords) showStartups('diffbot_keywords')}">{{stringHelper.formatNumber(counts.diffbot_keywords)}}</a></big>
                    </div>

                    <footer class="card__footer">
                        <h4 class="heading4 heading4--secondary">Populated with Youtube videos via Faiz</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.faiz_videos) showStartups('faiz_videos')}">{{stringHelper.formatNumber(counts.faiz_videos)}}</a></big>
                    </footer>
                </div>

                <div class="card card--block">
                    <div class="card__content">
                        <h4 class="heading4 heading4--secondary">Synched with KG</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.synced_kg) showStartups('synced_kg')}">{{stringHelper.formatNumber(counts.synced_kg)}}</a></big>
                    </div>

                    <footer class="card__footer">
                        <h4 class="heading4 heading4--secondary">Synched with CB</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.synced_cb) showStartups('synced_cb')}">{{stringHelper.formatNumber(counts.synced_cb)}}</a></big>
                    </footer>
                </div>

                <div class="card card--block">
                    <div class="card__content">
                        <h4 class="heading4 heading4--secondary">Synched with Ppx</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.synced_ppx) showStartups('synced_ppx')}">{{stringHelper.formatNumber(counts.synced_ppx)}}</a></big>
                    </div>

                    <footer class="card__footer">
                        <h4 class="heading4 heading4--secondary">Populated clients via Sylva script</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.sylva_clients) showStartups('sylva_clients')}">{{stringHelper.formatNumber(counts.sylva_clients)}}</a></big>
                    </footer>
                </div>

                <div class="card card--block">
                    <div class="card__content">
                        <h4 class="heading4 heading4--secondary">Startups Added</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.startups_added) showStartups('startups_added')}">{{stringHelper.formatNumber(counts.startups_added)}}</a></big>
                    </div>

                    <footer class="card__footer">
                        <h4 class="heading4 heading4--secondary">Startups Imported From Crunchbase</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.startups_cb_imported) showStartups('startups_cb_imported')}">{{stringHelper.formatNumber(counts.startups_cb_imported)}}</a></big>
                    </footer>
                </div>

                <div class="card card--block">
                    <div class="card__content">
                        <h4 class="heading4 heading4--secondary">Synced Patents</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.synced_patents) showStartups('synced_patents')}">{{stringHelper.formatNumber(counts.synced_patents)}}</a></big>
                    </div>

                    <div class="card__footer">
                        <h4 class="heading4 heading4--secondary">Not synced with any source</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.not_synced) showStartups('not_synced')}">{{stringHelper.formatNumber(counts.not_synced)}}</a></big>
                    </div>
                </div>

                <div class="card card--block">
                    <div class="card__content">
                        <h4 class="heading4 heading4--secondary">Hidden (Premium domain)</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.hidden_premium) showStartups('hidden_premium')}">{{stringHelper.formatNumber(counts.hidden_premium)}}</a></big>
                    </div>

                    <footer class="card__footer">
                        <h4 class="heading4 heading4--secondary">Hidden (Not working website)</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.hidden_not_working) showStartups('hidden_not_working')}">{{stringHelper.formatNumber(counts.hidden_not_working)}}</a></big>
                    </footer>
                </div>

                <div class="card card--block">
                    <div class="card__content">
                        <h4 class="heading4 heading4--secondary">Populated Clients via PPX (Old)</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.ppx_clients_old) showStartups('ppx_clients_old')}">{{stringHelper.formatNumber(counts.ppx_clients_old)}}</a></big>
                    </div>

                    <footer class="card__footer">
                        <h4 class="heading4 heading4--secondary">Populated Features via PPX (Old)</h4>
                        <div v-if="loadingCounters" class="d-flex justify-content-center">
                            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                        </div>
                        <big v-else class="card__big"><a @click="() => {if (counts.ppx_features_old) showStartups('ppx_features_old')}">{{stringHelper.formatNumber(counts.ppx_features_old)}}</a></big>
                    </footer>
                </div>
            </div>
        </div>

        <startups-modal-dashboard
            ref="startupsModal"
            :filter="filter"
        />
    </div>
</template>

<script>


import dashboardService from "@/services/dashboard.service";
import StartupsBySource from "@/components/charts/StartupsBySource.vue";
import StartupsAccessLevelsChart from "@/components/charts/StartupsAccessLevelsChart.vue";
import multiselect from "vue-multiselect";
import StartupsModalDashboard from "@/components/startups/StartupsModal.vue";

export default {
    components: {StartupsModalDashboard, multiselect, StartupsAccessLevelsChart, StartupsBySource},

    data() {
        return {
            loadingTypesChart: false,
            loadingSourceChart: false,
            loadingCounters: true,

            counts: {
                startups_cb_synced: {
                    count: null,
                    goal: null,
                },

                tracked_startups_cb_synced: {
                    count: null,
                    goal: null,
                },

                ecosystems_cb_synced: {
                    count: null,
                    goal: null,
                },

                ecosystems_relations_cb_synced: {
                    count: null,
                    goal: null,
                },

                ppx_clients: null,
                ppx_features: null,

                faiz_crawled: null,
                diffbot_crawled: null,

                diffbot_keywords: null,
                faiz_videos: null,

                synced_kg: null,
                synced_cb: null,

                synced_ppx: null,
                sylva_clients: null,

                startups_added: null,
                startups_cb_imported: null,

                synced_patents: null,
                not_synced: null,

                hidden_premium: null,
                hidden_not_working: null,

                ppx_clients_old: null,
                ppx_features_old: null,
            },

            selectedPeriod: "90",
            filterTracked: false,

            filterTrackedTypeOptions: [
                {
                    name: 'All',
                    value: 'all',
                },
                {
                    name: 'Paid',
                    value: 'paid',
                },
            ],

            filterTrackedType: {
                name: 'All',
                value: 'all',
            },

            startupsVisibilityTypes: [
                'All',
                'Public',
                'Public Not Approved',
                'Private',
                'Acquired',
                'Closed',
                'SP Inactive',
                'Hidden',
                '> 500 employees',
            ],

            startupVisibilityType: 'All',

            ecosystemStatuses: [
                'All',
                'Hidden',
                'Approved',
                'Rejected',
                'In Review',
            ],

            ecosystemStatus: 'All',
        }
    },

    watch: {
        filter() {
            this.getCounts();
        }
    },

    computed: {
        selectedPeriodLabel() {
            return this.periods.find(item => item.value === this.selectedPeriod).label
        },

        filter() {
            let filter = {
                period: this.selectedPeriod,
                startup_visibility: this.startupVisibilityType,
                ecosystem_status: this.ecosystemStatus,
            };

            if (this.filterTracked) {
                filter.tracked = this.filterTrackedType.value
            }

            return filter
        },

        periods() {
            let periods = [...this.constants.commonFilterPeriods];

            periods.splice(5, 0, ...[
                {
                    value: '2years',
                    label: 'Last 2 years'
                },
                {
                    value: '3years',
                    label: 'Last 3 years'
                },
            ])

            return periods;
        }
    },

    mounted() {
        this.getCounts();
        this.getCharts();
    },

    methods: {
        getCounts() {
            this.loadingCounters = true;

            dashboardService.getCounts(this.filter).then(response => {
                this.counts = response.data;
            }).finally(() => {
                this.loadingCounters = false;
            })
        },

        getCharts() {
            this.loadingTypesChart = true;
            this.loadingSourceChart = true;

            dashboardService.getAccessLevelsChartData().then(response => {
                this.$refs.startupsByAccessLevelsChart.draw(response.data)
            }).finally(() => {
                this.loadingTypesChart = false;
            });

            dashboardService.getSourceChartData().then(response => {
                this.$refs.startupsBySourceChart.draw(response.data)
            }).finally(() => {
                this.loadingSourceChart = false;
            });
        },

        showStartups(metric) {
            this.$refs.startupsModal.open(metric);
        },

        showChartStartups(chart, options) {
            this.$refs.startupsModal.openForChart(chart, options);
        },
    }
}
</script>
