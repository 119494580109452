import moment from 'moment-timezone'

const months = {
    1: 'JAN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AUG',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC',
}

export const decodeDate = (date, params = []) => {
    if (date) {
        date = moment(date)
        if (params.indexOf('month-str') >= 0) {
            let str = months[date.format('M')] + ' ' + date.format('DD')

            if (params.indexOf('no-current-year') < 0) {
                str += ' ' + date.format('YYYY')
            }

            return str
        }
        else if (params.indexOf('with-time') >= 0) {
            return date.format('MM/DD/YYYY HH:mm:ss')
        }
        else {
            return date.format('MM/DD/YYYY')
        }
    }
    else {
        return ""
    }
}

const decodeDateTz = (date, params = []) => {
    if (date) {
        date = moment(date).utc(true).tz(moment.tz.guess())
        if (params.indexOf('month-str') >= 0) {
            let str = months[date.format('M')] + ' ' + date.format('DD')

            if (params.indexOf('no-current-year') < 0) {
                str += ' ' + date.format('YYYY')
            }

            return str
        }
        else if (params.indexOf('with-time') >= 0) {
            return date.format('MM/DD/YYYY HH:mm:ss')
        }
        else {
            return date.format('MM/DD/YYYY')
        }
    }
    else {
        return ""
    }
}

const decodeYear = (date) => {
    return moment(date).format('YYYY')
}

export const encodeDateForServer = (date) => {
    if (date) {
        return moment(date).format('YYYY-MM-DD')
    }
    else {
        return ""
    }
}

export const decodeDateFromServer = (date) => {
    if (date) {
        return moment(date, 'YYYY-MM-DD').toDate()
    }
    else {
        return ""
    }
}

export const encodeDate = (date) => {
    return moment(date, 'MM/DD/YYYY').toDate()
}

const encodeInterval = (interval) => {
    let int = []
    int.push(encodeDate(interval[0]))
    int.push(encodeDate(interval[1]))
    return int
}

export const decodeIntervalFromServer = (interval) => {
    let int = []
    int.push(decodeDateFromServer(interval[0]))
    int.push(decodeDateFromServer(interval[1]))
    return int
}

const decodeInterval = (interval) => {
    let int = []
    int.push(decodeDate(interval[0]))
    int.push(decodeDate(interval[1]))
    return int
}

export const encodeIntervalForServer = (interval) => {
    let int = []
    int.push(encodeDateForServer(interval[0]))
    int.push(encodeDateForServer(interval[1]))
    return int
}

const decodeTime = (date) => {
    if (date) {
        date = moment(date)
        return date.format('hh:mm A')
    }
    else {
        return ""
    }
}

const decodeTimeTz = (date) => {
    if (date) {
        return moment(date).utc(true).tz(moment.tz.guess()).format('hh:mm A')
    } else {
        return ""
    }
}

const datesDiff = (firstDate, secondDate) => {
    return moment(firstDate).diff(secondDate);
}

const now = (format) => {
    return moment().format(format);
}

const firstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

const lastDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

const addMonth = (date, months) => {
    let year = date.getFullYear();
    let month = date.getMonth() + months;
    let day = date.getDate();

    if (month > 11) {
        month = month - 12;
        ++ year;
    } else if (month < 0) {
        month = month + 12;
        -- year;
    }

    return new Date(year, month, day);
}

export default {
    decodeDate,
    decodeDateTz,
    decodeYear,
    encodeDateForServer,
    encodeDate,
    decodeDateFromServer,
    encodeInterval,
    decodeIntervalFromServer,
    decodeInterval,
    encodeIntervalForServer,
    decodeTime,
    decodeTimeTz,
    datesDiff,
    now,
    firstDayOfMonth,
    lastDayOfMonth,
    addMonth,
}
