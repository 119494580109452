import { render, staticRenderFns } from "./IRLScore.vue?vue&type=template&id=4130c416&scoped=true&"
import script from "./IRLScore.vue?vue&type=script&lang=js&"
export * from "./IRLScore.vue?vue&type=script&lang=js&"
import style0 from "./IRLScore.vue?vue&type=style&index=0&id=4130c416&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../frontend-admin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4130c416",
  null
  
)

export default component.exports