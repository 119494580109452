<script setup>
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";
import Editor from "@tinymce/tinymce-vue";
import {computed, reactive, ref} from "vue";
import useForm from "../../../../frontend/src/composables/useForm";
import upcomingFeaturesService from "@/services/upcoming-features.service";
import {statuses} from "../../../../frontend/src/constants/upcomingFeatures";

const emit = defineEmits(['saved']);

const form = reactive({
    title: "",
    text: "",
    status: "draft",
})

const {populateForm, resetForm, setErrors, clearErrors} = useForm(form);
const showModal = ref(false);
const showEditor = ref(false);
const id = ref(null);
const loading = ref(false);

const editorOptions = {
    menubar: false,
    statusbar: false,
    plugins: ['lists','link','image'],
    images_upload_handler: uploadImage,
    toolbar: 'bold italic | alignleft aligncenter alignright | bullist numlist | link | image | removeformat',
    link_assume_external_targets: 'http'
}

const canSave = computed(() => form.title.length && form.text.length);

async function getData() {
    const {data} = await upcomingFeaturesService.getEdit(id.value);

    populateForm(data);
}

async function uploadImage(blobInfo, resolve) {
    const formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());

    const {data} = await upcomingFeaturesService.uploadImage(formData);
    resolve(data);
}

async function save() {
    loading.value = true;
    clearErrors();

    try {
        const {data} = await upcomingFeaturesService.save(id.value, form);

        if (!Object.keys(data.errors).length) {
            showModal.value = false;
            emit('saved');
        } else {
            setErrors(data.errors);
        }
    } finally {
        loading.value = false;
    }
}

function open(itemId) {
    resetForm();

    if (itemId) {
        id.value = itemId;
        getData();
    } else {
        id.value = 0;
    }

    showModal.value = true;
}

defineExpose({open});
</script>

<template>
    <b-modal
        v-model="showModal"
        modal-class="modal-w-md" :title="(id ? 'Edit' : 'Add') + ' Feature'"
        @shown="showEditor = true"
        @hidden="showEditor = false"
    >
        <form class="form pb-5">
            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Title *</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.title">
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Status *</h5>
                <select v-model="form.status" class="selectbox selectbox-mid w-100">
                    <option v-for="(label, value) in statuses" :key="value" :value="value">
                        {{label}}
                    </option>
                </select>
            </div>

            <editor
                v-if="showEditor"
                :apiKey="apiKeys.tinyMCE"
                v-model="form.text"
                :init="editorOptions"
            />
        </form>

        <template #modal-footer>
            <PrimaryButton
                :disabled="!canSave"
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<style scoped>

</style>
