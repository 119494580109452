import { render, staticRenderFns } from "./CustomFieldColumn.vue?vue&type=template&id=c8b5f246&scoped=true&"
import script from "./CustomFieldColumn.vue?vue&type=script&setup=true&lang=js&"
export * from "./CustomFieldColumn.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend-admin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8b5f246",
  null
  
)

export default component.exports