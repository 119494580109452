import axios from '@/axios';

const RESOURCE_NAME = '/ecosystems';

export default {
    getConnectedEcosystems(data) {
        return axios.post(`${RESOURCE_NAME}/get-connected-ecosystems`, data)
    },

    getDetails(id) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-details`)
    },

    getList(search, trackerId = false) {
        let data = {search: search}
        if (trackerId) {
            data.tracker_id = trackerId
        }
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getListForSearch(query) {
        return axios.get(`${RESOURCE_NAME}/get-list-for-search?query=${query}`)
    },

    getListForFilter(query) {
        return axios.get(`${RESOURCE_NAME}/get-list-for-filter?query=${query}`)
    },

    getConnectedEcosystemsList() {
        return axios.get(`${RESOURCE_NAME}/get-connected-ecosystems-list`)
    },

    getTopListForSearch() {
        return axios.get(`${RESOURCE_NAME}/get-list-for-search-top`)
    },

    getTasksCount(id) {
        return axios.get(`${RESOURCE_NAME}/${id}/get-tasks-count`)
    },

    getTrackersIds(id) {
        return axios.get(`${RESOURCE_NAME}/${id}/get-trackers-ids`);
    },

    getTopListForTracker(trackerId) {
        return axios.get(`${RESOURCE_NAME}/get-list-for-tracker-top?tracker_id=${trackerId}`)
    },

    getCommonData(id) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-common-data`)
    },

    getNews(id, pageParams = {}) {
        return axios.post(`${RESOURCE_NAME}/${id}/get-news`, pageParams)
    },

    getActivities(id, data) {
        return axios.post(`${RESOURCE_NAME}/` + id + `/get-activities`, data);
    },

    getContacts(id, search = '') {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-contacts?search=${search}`)
    },

    getContactsForSidePanel(id) {
        return axios.get(`${RESOURCE_NAME}/${id}/get-contacts-for-side-panel`);
    },

    getContactsIndex(id, search, pageParams = {}) {
        return axios.post(`${RESOURCE_NAME}/${id}/get-contacts-index?search=${search}`, pageParams)
    },

    getAvailableRecipients(id) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-available-recipients`)
    },

    getTrackers(id) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-trackers`)
    },

    getTrackersIndex(id, pageParams) {
        return axios.post(`${RESOURCE_NAME}/get-trackers-index?id=${id}`, pageParams);
    },

    getNoteEdit(id, noteId) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-note-edit?note_id=${noteId}`)
    },

    saveNote(id, noteId, data) {
        return axios.post(`${RESOURCE_NAME}/` + id + `/save-note?note_id=${noteId}`, data)
    },

    getStartups(id, data, pageParams = {}) {
        return axios.post(`${RESOURCE_NAME}/` + id + `/get-startups`, {
            ...data,
            ...pageParams
        })
    },

    getStartupsCategories(id) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-startups-categories`)
    },

    addConnection(data) {
        return axios.post(`${RESOURCE_NAME}/add-connection`, data)
    },

    removeConnections(ecosystemIds) {
        return axios.post(`${RESOURCE_NAME}/remove-connections`, {ecosystem_ids: ecosystemIds})
    },

    removeAllConnections() {
        return axios.post(`${RESOURCE_NAME}/remove-all-connections`)
    },

    getPortfolioInfo(id) {
        return axios.get(`${RESOURCE_NAME}/get-portfolio-info?portfolio_id=${id}`)
    },

    getPortfolioStartups(id, data) {
        return axios.post(`${RESOURCE_NAME}/get-portfolio-startups?portfolio_id=${id}`, data)
    },

    getSharedPortfolios(id) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-shared-portfolios`)
    },

    getAccountInfo(id) {
        return axios.get(`${RESOURCE_NAME}/` + id + `/get-account-info`)
    },

    addErrorReport(id, data) {
        return axios.post(`${RESOURCE_NAME}/` + id + `/add-error-report`, data)
    },

    getInvitedCount() {
        return axios.get(`${RESOURCE_NAME}/get-invited-count`)
    },

    addConnectionRequest(data) {
        return axios.post(`${RESOURCE_NAME}/add-connection-request`, data)
    },

    setConnectionRequestStatus(id, status) {
        return axios.post(`${RESOURCE_NAME}/${id}/set-connection-request-status`, {status})
    },

    getConnectionStatus(id) {
        return axios.get(`${RESOURCE_NAME}/${id}/get-connection-status`);
    },

    setFavorite: (id, is_favorite) => axios.post(`${RESOURCE_NAME}/` + id + `/set-favorite`, { is_favorite })
}
