<script setup>
import Grid from "../../../../frontend/src/components/Grid.vue";
import useGrid from "@/composables/useGrid";
import {onMounted, ref} from "vue";
import upcomingFeaturesService from "@/services/upcoming-features.service";
import FeatureEdit from "@/components/upcomingFeatures/FeatureEdit.vue";

const {gridParams, gridMethods} = useGrid(upcomingFeaturesService.getList);

const columns = [
    {
        label: 'Title',
        name: 'title',
    },
    {
        label: 'Status',
        name: 'status',
    },
    {
        label: 'Created At',
        name: 'created_at',
        component: 'Date',
        sortable: true,
    },
    {
        name: 'actions',
        label: 'Actions',
        component: 'Actions',
    },
];

const editFeature = ref(null);

onMounted(() => {
    gridMethods.getList();
});

function gridAction(params) {
    if (params.action === 'edit') {
        editFeature.value.open(params.rowId);
    } else if (params.action === 'delete') {
        if (confirm("Delete feature?")) {
            upcomingFeaturesService.delete(params.rowId).then(() => {
                gridMethods.getList()
            })
        }
    }
}
</script>

<template>
    <div>
        <div class="topbar pl-sm-5">
            <div class="topbar__inner">
                <div class="topbar__key">
                    <header>
                        <button class="button w-100 w-sm-auto" @click="editFeature.open()">Add Feature</button>
                    </header>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper">
            <div class="main-table overflow-auto">
                <Grid
                    :columns="columns"
                    :data="gridParams.items"
                    :page-length="parseInt(gridParams.pageLength)"
                    :total-rows-count="gridParams.totalRowsCount"
                    :sort="gridParams.sort"
                    :currentPage="gridParams.currentPage"
                    v-on:changePage="gridMethods.changePage"
                    v-on:action="gridAction"
                />
            </div>
        </div>

        <FeatureEdit
            ref="editFeature"
            @saved="gridMethods.getList"
        />
    </div>
</template>

<style scoped>

</style>
