import {reactive, watch} from "vue";

export default function (getListMethod, pageLength = 25) {
    const gridParams = reactive({
        items: [],
        columns: [],
        currentPage: 1,
        pageLength: pageLength,
        totalRowsCount: 0,
        pagesCount: 0,
        filters: {},
        sort: {
            field: '',
            dir: '',
        },
        selectedRows: [],
        selectedAllResults: false,
    })

    watch(() => gridParams.totalRowsCount, () => {
        gridParams.pagesCount = Math.ceil(gridParams.totalRowsCount / gridParams.pageLength);
    });

    function getCommonRequestParams(includeFilters = true) {
        let params = {
            columns: gridParams.columns.map(column => column.name),
            sort: gridParams.sort,
            currentPage: gridParams.currentPage,
            pageLength: gridParams.pageLength,
        };

        if (includeFilters) {
            params.filters = gridParams.filters;
        }

        return params;
    }

    async function getList() {
        gridParams.loading = true;
        gridParams.items = [];
        gridParams.totalRowsCount = 0;

        try {
            const {data} = await getListMethod(getCommonRequestParams());
            gridParams.items = data.data;
            gridParams.totalRowsCount = parseInt(data.total_count);
            gridParams.currentPage = data.current_page;
        } finally {
            gridParams.loading = false;
        }
    }

    function changePage(page) {
        gridParams.currentPage = page
        getList();
    }

    function changeSort(sort) {
        gridParams.sort = sort
        getList();
    }

    const gridMethods = {
        getList,
        changePage,
        changeSort,
    }

    return {
        gridParams,
        gridMethods
    }
}
