import {reactive} from "vue";
import {snakeToCamelCase} from "../../../frontend/src/helpers/string";

export default function (form) {
    const defaultValues = JSON.stringify(form);

    const errors = reactive(Object.keys(form).reduce((acc, key) => {
        acc[key] = [];
        return acc;
    }, {}));

    function hasErrors(data) {
        if (!Object.keys(data.errors).length) {
            clearErrors();

            return false;
        } else {
            setErrors(data.errors);

            return true;
        }
    }

    function setErrors(data) {
        for (const key in data) {
            let snakeCaseKey = snakeToCamelCase(key);
            errors[snakeCaseKey] = data[key];
        }
    }

    function clearErrors() {
        for (const key in errors) {
            errors[key] = [];
        }
    }

    function populateForm(data) {
        Object.keys(data).forEach(key => {
            if (key in form) {
                form[key] = data[key];
            } else {
                let snakeCaseKey = snakeToCamelCase(key);

                if (snakeCaseKey in form) {
                    form[snakeCaseKey] = data[key];
                }
            }
        });
    }

    function resetForm() {
        let defaultObject = JSON.parse(defaultValues);

        Object.keys(form).forEach(key => {
            form[key] = defaultObject[key];
        });
    }

    return {
        populateForm,
        resetForm,
        errors,
        hasErrors,
        setErrors,
        clearErrors,
    };
}
