<template>
    <div>
        <div class="topbar pl-sm-5">
            <div class="topbar__inner">
                <div class="topbar__key">
                    <header>
                        <button class="button w-100 w-sm-auto" @click="add">Add Help Record</button>
                    </header>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper">
            <div class="main-table overflow-auto">
                <Grid
                    :has-filters="true"
                    :columns="columns"
                    :data="items"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :notSelectWhole="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                />
            </div>
        </div>

        <edit-item
            ref="editItem"
            @saved="getList"
        />

        <video-modal
            ref="videoModal"
        />

    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid.vue";
import grid from "../../../../frontend/src/mixins/grid";
import service from "@/services/helper-items.service";
import EditItem from "@/components/helperItems/HelperItemEdit.vue";
import helperItemsService from "@/services/helper-items.service";
import VideoModal from "../../../../frontend/src/components/widgets/VideoModal";
import {helperTypes, pages, statuses} from "../../../../frontend/src/constants/helperItems";

export default {
    components: {
        EditItem,
        Grid,
        VideoModal,
    },

    mixins: [grid],

    data() {
        return {
            getListMethod: service.getList,

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Notes',
                    name: 'notes',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Type',
                    name: 'type',
                    filter: 'single-select',
                    options: Object.entries(helperTypes).map(([id, name]) => ({id, name})),
                    sortable: true,
                },
                {
                    label: 'Pages',
                    name: 'pages',
                    filter: 'single-select',
                    options: Object.entries(pages).map(([id, name]) => ({id, name})),
                },
                {
                    label: 'Source ID',
                    name: 'source_id',
                    filter: 'text',
                    sortable: true,
                },
                {
                    label: 'Status',
                    name: 'status',
                    filter: 'single-select',
                    options: Object.entries(statuses).map(([id, name]) => ({id, name})),
                    sortable: true,
                },
                {
                    label: 'Created At',
                    name: 'created_at',
                    component: 'Date',
                    sortable: true,
                },
                {
                    label: 'Updated At',
                    name: 'updated_at',
                    component: 'Date',
                    sortable: true,
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                },
            ],
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        add() {
            this.$refs.editItem.open();
        },

        gridAction(params) {
            if (params.action === 'edit') {
                this.$refs.editItem.open(params.rowId);
            } else if (params.action === 'show') {
                let item = this.items.find(item => item.id ===params.rowId);

                if (item) {
                    if (item.type === 'Video' && item.show) {
                        this.$refs.videoModal.show(item.show);
                    } else if (item.show) {
                        window.open(item.show, '_blank')
                    }
                }
            } else if (params.action === 'delete') {
                if (confirm("Delete helper?")) {
                    helperItemsService.delete(params.rowId).then(() => {
                        this.getList()
                    })
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
