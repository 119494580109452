<script setup>
import {computed, reactive, ref, watch} from "vue";
import Editor from "../../../../frontend/src/components/Editor.vue";
import Multiselect from "vue-multiselect";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";
import {helperTypes, pages, statuses} from "../../../../frontend/src/constants/helperItems";
import helperItemsService from "@/services/helper-items.service";
import useForm from "../../../../frontend/src/composables/useForm";

const editorOptions = {
    menubar: false,
    statusbar: false,
    plugins: ['lists','link','image'],
    images_upload_handler: uploadImage,
    toolbar: 'bold italic | alignleft aligncenter alignright | bullist numlist | link | image | removeformat',
    link_assume_external_targets: 'http'
}

const showModal = ref(false);
const id = ref(0);

const form = reactive({
    name: "",
    notes: "",
    type: null,
    sourceId: "",
    status: null,
    thumbnailUrl: null,
    isPrivate: null,
    substitutionFor: null,
    internalArticle: null,
    text: '',
});

const {populateForm, resetForm, errors, setErrors, clearErrors} = useForm(form);
const emit = defineEmits(['saved']);

const loading = ref(false);
const selectedPages = ref([]);
const selectedEnterprises = ref([]);
const substitutionFor = ref(null);
const enterprisesList = ref([]);
const isSubstitution = ref(false);
const commonItems = ref(null);

const title = computed(() => (id.value ? "Edit " : "Add New ") + "Help Record");
const pagesList = computed(() => Object.keys(pages).map(key => ({value: key, name: pages[key]})));
const canSave = computed(() => form.name.length && form.type && form.status && selectedPages.value.length);
const availableCommonItems = computed(() => commonItems.value?.filter(item => item.id !== id.value && item.type === form.type) ?? []);

watch(isSubstitution, value => {
    if (value && commonItems.value === null) {
        getCommonItems();
    }
});

async function save() {
    loading.value = true;
    clearErrors();
    form.pages = selectedPages.value.map(page => page.value);

    if (form.isPrivate) {
        form.enterprises = selectedEnterprises.value.map(enterprise => enterprise.id);
    } else {
        form.enterprises = [];
    }

    if (form.isPrivate && isSubstitution.value && substitutionFor.value) {
        form.substitutionFor = substitutionFor.value.id;
    } else {
        form.substitutionFor = null;
    }

    try {
        const {data} = await helperItemsService.save(id.value, form);

        if (!Object.keys(data.errors).length) {
            showModal.value = false;
            emit('saved');
        } else {
            setErrors(data.errors);
        }
    } finally {
        loading.value = false;
    }
}

async function getData() {
    const {data} = await helperItemsService.getEdit(id.value);

    populateForm(data);
    selectedEnterprises.value = data.enterprises;
    selectedPages.value = data.pages.map(page => ({value: page, name: pages[page]}));

    if (form.substitutionFor) {
        isSubstitution.value = true;
        substitutionFor.value = commonItems.value?.find(item => item.id === form.substitutionFor);
    }
}

async function getCommonItems() {
    const {data} = await helperItemsService.getCommonItems();
    commonItems.value = data;

    if (form.substitutionFor) {
        substitutionFor.value = commonItems.value.find(item => item.id === form.substitutionFor);
    }
}

async function sourceIdFilled() {
    if (form.type === 'video' && form.sourceId.length) {
        const {data} = await helperItemsService.getVideoInfo(form.sourceId);
        form.thumbnailUrl = null;

        if (data) {
            if ('title' in data) {
                form.name = data.title;
            }

            if ('thumbnail_url' in data) {
                form.thumbnailUrl = data.thumbnail_url;
            }
        }
    }
}

async function updateEnterprises(query) {
    if (query.length) {
        const {data} = await helperItemsService.getEnterprisesList(query);
        enterprisesList.value = data;
    } else {
        enterprisesList.value = [];
    }
}

async function uploadImage(blobInfo, resolve) {
    const formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());

    const {data} = await helperItemsService.uploadImage(formData);
    resolve(data);
}

function open(itemId) {
    resetForm();
    selectedPages.value = [];
    isSubstitution.value = false;
    substitutionFor.value = null;

    if (itemId) {
        id.value = itemId;
        getData();
    } else {
        id.value = 0;
    }

    showModal.value = true;
}

defineExpose({open});
</script>

<template>
    <b-modal v-model="showModal" modal-class="modal-w-xxl" :title="title">
        <form class="form pb-5">
            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Type *</h5>

                <select v-model="form.type" class="selectbox selectbox-mid w-100">
                    <option v-for="(label, value) in helperTypes" :key="value" :value="value">
                        {{label}}
                    </option>
                </select>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Source ID</h5>
                <input @blur="sourceIdFilled" class="form-control--mid form-control" type="text" v-model="form.sourceId">
                <span v-if="errors.sourceId.length" class="form__error">{{errors.sourceId[0]}}</span>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Name *</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.name">
                <span v-if="errors.name.length" class="form__error">{{errors.name[0]}}</span>
            </div>

            <figure v-if="form.thumbnailUrl">
                <img :src="form.thumbnailUrl" :alt="form.name"/>
            </figure>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Notes</h5>
                <textarea class="form-control--mid form-control" v-model="form.notes"/>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Page *</h5>

                <Multiselect
                    v-model="selectedPages"
                    multiple
                    placeholder="Select Pages"
                    :options="pagesList"
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                />
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Status *</h5>
                <select v-model="form.status" class="selectbox selectbox-mid w-100">
                    <option v-for="(label, value) in statuses" :key="value" :value="value">
                        {{label}}
                    </option>
                </select>
            </div>

            <div class="form__item input-checkbox">
                <input id="isPrivate" type="checkbox" v-model="form.isPrivate">
                <label for="isPrivate">Is Private</label>
            </div>

            <template v-if="form.isPrivate">
                <div class="form__item">
                    <h5 class="heading5 heading5--secondary">Select Enterprises</h5>

                    <Multiselect
                        v-model="selectedEnterprises"
                        multiple
                        placeholder="Select Company"
                        :options="enterprisesList"
                        label="name"
                        track-by="name"
                        class="multiselect-mid pl-2"
                        @search-change="updateEnterprises"
                    />
                </div>

                <div v-if="form.type" class="form__item input-checkbox">
                    <input id="substitutionFor" type="checkbox" v-model="isSubstitution">
                    <label for="substitutionFor">Is Substitution</label>
                </div>

                <div v-if="isSubstitution" class="form__item">
                    <h5 class="heading5 heading5--secondary">Select Record to Substitute</h5>

                    <Multiselect
                        v-model="substitutionFor"
                        placeholder="Select Record"
                        :options="availableCommonItems"
                        label="name"
                        track-by="name"
                        class="multiselect-mid pl-2"
                    />
                </div>
            </template>

            <template v-if="form.type === 'knowledge_base'">
                <div class="form__item input-checkbox">
                    <input id="internal_article" type="checkbox" v-model="form.internalArticle">
                    <label for="internal_article">Internal Article</label>
                </div>

                <Editor
                    v-if="form.internalArticle"
                    v-model="form.text"
                    :init="editorOptions"
                />
            </template>
        </form>

        <template #modal-footer>
            <PrimaryButton
                :disabled="!canSave"
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<style scoped>

</style>
