import axios from '@/axios';

const RESOURCE_NAME = '/admin/upcoming-features';

export default {
    getList(data) {
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-feature?id=${id}`)
    },

    uploadImage(data) {
        return axios.post(`${RESOURCE_NAME}/upload-file`, data);
    },
}
